import React, { ChangeEvent } from 'react';

interface InputNumberProps {
    value: string;
    className?: string;
    placeholder: string;
    onChange: (value: string) => void;
}

const InputNumber: React.FC<InputNumberProps> = ({ value, placeholder, className= '', onChange }) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        onChange(numericValue);
    };

    return (
        <input
            type="text"
            className={className}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
        />
    );
};

export default InputNumber;
